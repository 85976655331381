import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import ParticlesConfig from "../config/particles"
import Particles from "react-particles-js"
import Typed from "react-typed"

import Layout from "../layouts/layout"
import { screenWidthChecker } from "~c/utils/base"

import "./index.scss"



const IndexPage = () => {

  return (
    <Layout
      meta={{
        title: "Home",
      }}
    >
      <div className="view view-index">
        { screenWidthChecker() !== 'mobile' ? <Particles params={ParticlesConfig } /> : ''}
        <section className="view-index__hero">
          <h1 className="visually-hidden">My name is Sergey Nazarov and I'm Professional Web Engineer with more than 5 years experience</h1>
          <div className="view-index__introduce">
            <div className="flip-card flip-card--small">
              <div className="flip-card__inner">
                <div className="flip-card__front flip-card__front--round">
                  <StaticImage
                    src="../assets/images/portrait-small.jpg"
                    alt="Sergey Nazarov"
                    quality={100}
                  />
                </div>
                <div className="flip-card__back flip-card__back--round bg-primary">
                  <div className="d-flex align-items-center justify-content-center h-100"></div>
                </div>
              </div>
            </div>
            <div className="view-index__title">
              <div>Hi Stranger,</div>
              I'm&nbsp;
              <Typed
                strings={[
                  "Sergey Nazarov",
                  "Web Engineer",
                  "in an industry for 7 years",
                  "Master in Computer Science",
                  "developed 30+ projects",
                ]}
                typeSpeed={60}
                backSpeed={40}
                loop
              />
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default IndexPage
